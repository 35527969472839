body{
  padding:15px;
}

header{
  display:flex;
  align-items:center;
}

header h1{
  font-size:21px;
}

section p{
  color:#3e3e3e;
}

img{
  width:100%;
  height:auto;
}

header .photo{
  border-radius:100%;
  overflow:clip;
  width:40px;
  height:40px;
  margin-right:10px;
}

#Projects{
  margin-top:30px;
}

#Projects h3{
  margin-bottom:5px;
}

#Projects code a{
  color:#239471;
  font-size:13px;
  text-decoration:none;
}

#Projects code a:hover{
  text-decoration:underline;
}